<template>
  <div class="active-side">
    <a-row>
      <a-col :span="12">
        <a-button type="primary" shape="round" ghost size="large" @click="copy2Clipboard('Yxbjzp')">
          <img src="@/assets/image/erweima.png" alt="微信咨询">
          <span>微信咨询</span>
        </a-button>
      </a-col>
      <a-col :span="12">
        <a-button type="primary" shape="round" size="large">
          <a href="tel:17719827554">
            <img src="@/assets/image/action-side/mobile/phone.png" alt="电话咨询">
            <span>电话咨询</span>
          </a>
        </a-button>
      </a-col>
    </a-row>
    <!--<div class="service">
      <a href="https://p.qiao.baidu.com/cps/chat?siteId=17375114&userId=32548419&siteToken=2fb655d027ede9e26478158ba5016465" target="_blank">
        <img src="@/assets/image/action-side/mobile/service.png" alt="在线客服">
      </a>
    </div>
    <div class="try">
      <a href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s" target="_blank">
        <img src="@/assets/image/action-side/mobile/try.png" alt="体验试用">
      </a>
    </div>-->
    <a-modal centered :footer="null" dialogClass="dialog" :visible="visible" @cancel="handleCancel">
      <div class="success">
        <img src="@/assets/image/action-side/mobile/success.png" alt="成功">
        <h1>复制成功</h1>
        <p>微信号：Yxbjzp</p>
        <p>添加微信好友，详细咨询</p>
        <a-button type="primary" shape="round" size="large" v-if="openWeChat">
          <a href="weixin://">打开微信</a>
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'ActionSideMobile',
  data() {
    return {
      visible: false,
      openWeChat: true
    }
  },
  created() {
    this.isWeChat()
  },
  methods: {
    copy2Clipboard(content) {
      const dom = document.createElement('input')
      dom.value = content
      document.body.appendChild(dom)
      dom.select()
      document.execCommand('copy')
      document.body.removeChild(dom)
      this.visible = true
    },
    handleCancel() {
      this.visible = false
    },
    isWeChat() {
      // window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
      var ua = window.navigator.userAgent.toLowerCase()
      // 通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if(ua.match(/MicroMessenger/i) == 'micromessenger') {
        this.openWeChat = false
      } else {
        this.openWeChat = true
      }
    }
  }
}
</script>

<style lang='less' scoped>
.active-side {
  .ant-row {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #FFFFFF;
    border-top: 1px solid #E2E2E2;
    width: 100%;
    .ant-col {
      padding: 20px 10px;
      .ant-btn {
        width: 100%;
        img {
          width: 20px;
          height: 20px;
          margin-right: 4px;
        }
      }
    }
  }
  .service, .try {
    position: fixed;
    right: 10px;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
    img {
      width: 80px;
      height: 80px;
    }
  }
  .service {
    bottom: 220px;
  }
  .try {
    bottom: 120px;
  }
  a {
    display: block;
  }
}
/deep/ .dialog {
  .ant-modal-content {
    border-radius: 20px;
  }
}
.success {
  text-align: center;
  img {
    width: 40px;
    height: 40px;
  }
  h1 {
    margin: 12px 0 0;
    font-size: 18px;
    color: #333333;
  }
  p {
    margin: 12px 0 0;
    color: #5C5C5C;
  }
  .ant-btn {
    margin-top: 18px;
  }
}
</style>
