export default [
  {
    name: 'home',
    path: '/',
    component: () => import('../views/HomePage'),
    meta: {
      title: '首页',
      opacityMenu:true
    }
  },{
    name: 'product',
    path: '/product',
    component: () => import('../views/ProductService'),
    meta: {
      title: '产品服务',
      opacityMenu:true
    }
  },{
    name: 'solution',
    path: '/solution',
    component: () => import('../views/Solution'),
    meta: {
      title: '解决方案',
      opacityMenu:true
    }
  },{
    name: 'solutionDetails',
    path: '/solutionDetails/:name',
    component: () => import('../views/SolutionDetails'),
    meta: {
      title: '解决方案详情',
      opacityMenu:true
    }
  },{
    name: 'news',
    path: '/news',
    component: () => import('../views/NewsCenter'),
    meta: {
      title: '新闻中心',
      opacityMenu:true
    }
  },{
    name: 'about',
    path: '/about',
    component: () => import('../views/AboutUs'),
    meta: {
      title: '关于我们',
      opacityMenu:true
    }
  },{
    name: 'details',
    path: '/details/:id',
    component: () => import('../views/NewsDetails'),
    meta: {
      title: '新闻详情',
      opacityMenu:false
    }
  },{
    name: 'login',
    path: '/logindex',
    component: () => import('../views/loginIndex'),
    meta: {
      title: '登录',
      opacityMenu:true
    }
  }
]
