<template>
  <div class="active-side" v-show="shopShow">
    <!--<a href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s" target="_blank">
      <img src="@/assets/image/action-side/pc/1.png" alt="体验试用">
      <span>体验试用</span>
    </a>
    <a href="https://p.qiao.baidu.com/cps/chat?siteId=17375114&userId=32548419&siteToken=2fb655d027ede9e26478158ba5016465"
       target="_blank">
      <img src="@/assets/image/action-side/pc/2.png" alt="在线客服">
      <span>在线客服</span>
    </a>-->
    <img src="@/assets/image/erweima_xuan.png" alt="" class="info">
    <br>
    <img src="@/assets/image/close_img.png" alt="" class="close" @click="closeImg">
    <!--<a-popover placement="left" overlayClassName="qrcode-popover" :getPopupContainer="triggerNode => triggerNode.parentNode">
      <template slot="content">
        <img class="wx-qrcode" src="@/assets/image/erweima.png" alt="微信咨询">
        <span>添加微信咨询</span>
      </template>
      <a>
        <img src="@/assets/image/action-side/pc/3.png" alt="微信咨询">
        <span>微信咨询</span>
      </a>
    </a-popover>
    <a-popover placement="left" overlayClassName="phone-popover" :getPopupContainer="triggerNode => triggerNode.parentNode">
      <template slot="content">
        &lt;!&ndash;&ndash;&gt;
        &lt;!&ndash;17719827554&ndash;&gt;
        <a-icon type="phone" theme="filled" :rotate="90"/>
        17719827554
      </template>
      <a>
        <img src="@/assets/image/action-side/pc/4.png" alt="电话咨询">
        <span>电话咨询</span>
      </a>
    </a-popover>-->
    <!--<a @click="clickToBakcTop">
      <img src="@/assets/image/action-side/pc/5.png" alt="回到顶部">
      <span>回到顶部</span>
    </a>
    <a-back-top ref="backTop" :visibilityHeight="0" style="display: none !important;"/>-->
  </div>
</template>

<script>
export default {
  name: 'ActionSidePc',
  data () {
    return {
      shopShow: true, //默认内容显示
    }
  },
  methods: {
    clickToBakcTop() {
      const $backTop = this.$refs.backTop
      if ($backTop && $backTop.$el) {
        $backTop.$el.click()
      }
    },
    closeImg(){
      this.shopShow = !this.shopShow //使true变为隐藏false
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .qrcode-popover .ant-popover-inner {
  border-radius: 12px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);

  .ant-popover-inner-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;

    .wx-qrcode {
      width: 132px;
      height: 132px;
    }
  }
}

/deep/ .phone-popover .ant-popover-inner {
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.active-side {
  /*width: 75px;
  height: 260px;
  background: #1890FF;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  opacity: 1;
  border-radius: 10px;
  position: fixed;
  bottom: calc(50% - 400px);
  right: 0;
  display: flex;
  flex-direction: column;*/
  position: fixed;
  bottom: 14px;
  right: 10px;
  text-align: center;
  .info {
    width: 225px;
  }
  .close {
    margin-top: 4px;
    width: 30px;
    cursor: pointer;
  }
}

.active-side > a {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  color: #ffffff;
  font-size: 12px;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, .3);

  &:first-of-type {
    border-top: none;
  }
}

.active-side > a > img {
  height: 20px;
  object-fit: contain;
}
</style>
