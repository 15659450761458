<template>
  <pc v-if="screenWidth > 768" />
  <mobile v-else />
</template>

<script>
import pc from './pc.vue'
import mobile from './mobile.vue'
export default {
  name: 'ActionSide',
  components:{
    pc,
    mobile
  },
  data() {
    return {
      screenWidth: document.body.clientWidth
    }
  },
  created() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
      })()
    }
  }
}
</script>

<style lang='less' scoped>
</style>
