<template>
  <a-config-provider :locale="zh_CN">
    <div id="app">
      <public-header :isOpacity="$store.state.isOpacity"></public-header>
      <router-view></router-view>
      <public-footer></public-footer>
      <action-side />
    </div>
  </a-config-provider>
</template>

<script>
import ActionSide from '@/components/ActionSide/index.vue'
import PublicFooter from './components/PublicFooter'
import PublicHeader from './components/PublicHeader'
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
export default {
  name: 'App',
  components:{
    ActionSide,
    PublicFooter,
    PublicHeader
  },
  data() {
    return {
      zh_CN
    }
  }
}
</script>

<style>
#app {
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 14px;
}
</style>
