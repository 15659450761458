<template>
  <div class="container">
    <div :class="[{isopacity:isOpacity},'pc']">
      <div class="header_con">
        <div class="logo"><router-link :to="{name:'home'}"><img :src="src" alt=""></router-link></div>
        <div class="nav">
          <a-menu v-model="current" mode="horizontal" @click="handleClick">
            <a-menu-item v-for="item in options" :key="item.name">{{item.label}}</a-menu-item>
          </a-menu>
        </div>
        <router-link :to="{path:'/logindex'}" ><span class="login">登录</span></router-link>
        <!--<a-button type="primary" size="large" class="try">
          <a href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s" target="_blank">体验试用</a>
        </a-button>-->
      </div>
    </div>
    <div class="mobile">
      <div class="header">
        <div class="a-btn" @click="visible=true">
          <a-icon type="menu" />
        </div>
        <router-link :to="{name:'home'}"><img src="../assets/image/logo_1.png" alt=""></router-link>
      </div>
      <a-drawer
          placement="left"
          :closable="true"
          :visible="visible"
          @close="visible=false"
      >
        <div class="drawer_con">
          <p class="menu_item" @click="visible=false" v-for="i in options" :key="i.name"><router-link :to="{name:i.name}">{{ i.label }}</router-link></p>
        </div>
      </a-drawer>
    </div>
  </div>
</template>

<script>
export default {
  name: "PublicHeader",
  data(){
    return{
      options:[{
        name:'home',
        label:'首页'
      },{
        name:'product',
        label:'产品服务'
      },{
        name:'solution',
        label:'解决方案'
      },{
        name:'news',
        label:'新闻中心'
      },{
        name:'about',
        label:'关于我们'
      }],
      current:[],
      visible:false
    }
  },
  props:['isOpacity'],
  computed:{
    src(){
      const logo1=require('../assets/image/logo_1.png')
      const logo2=require('../assets/image/logo_opacity.png')
      return this.isOpacity?logo2:logo1
    }
  },
  methods:{
    goLogin(){

    },
    handleClick(e){
      if(e.key&&e.key!=this.$route.name){
        this.$router.push({name:e.key})
      }
    },
    handleScroll(){
      if(this.$route.name!='details'){
        const scrollTop  = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
        let sh=this.$route.name=='home'?200:100
        this.$store.commit('changeOpacity',scrollTop <sh)
      }
    }
  },
  watch:{
    $route(to){
      this.current=[to.name]
    }
  },
  mounted () {
    window.onscroll=()=>{
      this.handleScroll()
    }
  },
}
</script>

<style lang="less" scoped>
@media screen and ( max-width: 768px){
  .pc{
    display: none;
  }
  .mobile{
    display: block;
  }
}
@media screen and ( min-width: 768px){
  .pc{
    display: block;
  }
  .mobile{
    display: none;
  }
}
.pc{
  width: 100%;
  height: 72px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  box-shadow: 0px 7px 22px 0 rgba(0, 0, 0, 0.08);
  .header_con{
    width: 1200px;
    margin: 0 auto;
    position: relative;
    .logo{
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 18px;
    }
    .nav{
      position: absolute;
      right: 150px;
      padding-top: 10px;
      .ant-menu-horizontal{
        border-bottom: none;
      }
      .ant-menu-item{
        padding: 0;
        margin: 0 20px;
        font-size: 16px;
      }
      .ant-menu-item-selected{
        color: inherit;
      }
    }
    .login{
      position: absolute;
      right: 0;
      color: rgba(0, 0, 0, 0.65);
      padding-top: 10px;
      line-height: 48px;
      font-size: 16px;

      &:first-of-type{
        right: 100px;
      }

      &:hover{
        color: #1890ff !important;
      }
    }
    .try {
      position: absolute;
      top: 14px;
      right: -24px;
      a {
        display: block;
      }
    }
  }
}
.mobile{
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  .header{
    padding: 12px;
    height: 50px;
    background-color: #fff;
    box-shadow: 0px 7px 22px 0 rgba(0, 0, 0, 0.08);
    img{
      width: 56px;
      float: right;
    }
    .a-btn{
      float: left;
      color: #333;
      font-size: 18px;
    }
  }
}
.drawer_con{
  padding-top: 40px;
  .menu_item{
    line-height: 70px;
    padding-left: 20px;
    font-size: 16px;
    a{
      color: #333;
    }
  }
}
.isopacity{
  background-color: transparent;
  box-shadow: 0px 7px 22px 0 rgba(0, 0, 0, 0);
  ul{
    color: #fff;
    background-color: transparent;
  }
  .login{
    color: #fff !important;
  }
}
/deep/ .ant-menu{
  background-color: transparent;
}
</style>
