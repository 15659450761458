<template>
  <div class="container">
    <div class="top">
      <div class="top_con">
        <div class="top_col span1">
          <dl>
            <dt>我们的愿景</dt>
            <dd>做最全生态的智慧社区系统定制服务商</dd>
            <dt>我们的使命</dt>
            <dd>让社区管理更高效，让住户生活更便捷</dd>
          </dl>
        </div>
        <div class="top_col span2">
          <dl>
            <dt>联系我们</dt>
            <dd>17719827554</dd>
            <dd>业务合作：qyx@9zhinet.com</dd>
            <dd>简历投递：hr@9zhinet.com</dd>
          </dl>
        </div>
        <div class="top_col span3">
          <dl>
            <dt>公司地址</dt>
            <dd>郑州总部：郑州市二七区漓江路23号2号楼2层</dd>
          </dl>
        </div>
        <div class="top_col span4">
          <a-row class="wechat" type="flex" justify="space-around">
            <a-col class="items" :span="12">久智公众号</a-col>
            <a-col class="items items2" :span="12">扫码合作</a-col>
          </a-row>
        </div>
      </div>
    </div>
    <div class="bottom">
      <p>Copyright © 2022河南久智网络科技有限公司 豫ICP备17020190号-1 </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PublicFooter"
}
</script>

<style lang="less" scoped>
.top{
  padding: 60px 20px 0;
  box-sizing: border-box;
  background-color: #242525;
  height: 255px;
  .top_con{
    width: 1200px;
    margin: 0 auto;
    color: #fff;
    overflow: hidden;
    .top_col{
      float: left;
    }
    .span1{
      width: 30%;
    }
    .span2{
      width: 25%;
    }
    .span3{
      width: 25%;
      dd{
        width: 200px;
      }
    }
    .span4{
      width: 20%;
      padding: 0 10px;
    }
    dt{
      font-size: 16px;
      margin-bottom: 12px;
    }
    dd{
      color: #999;
      font-size: 14px;
      margin-bottom: 12px;
    }
    .wechat{
      .items{
        color: #eee;
        width: 90px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        position: relative;
        top: 100px;
        &:after{
          content: '';
          width: 90px;
          height: 90px;
          background: url("../assets/image/erweima2.png") no-repeat center;
          background-size: contain;
          position: absolute;
          top: -100px;
          left: 0;
        }
        &.items2:after{
          background-image: url("../assets/image/erweima.png");
        }
      }
    }
  }
}
.bottom{
  p{
    line-height: 20px;
    text-align: center;
    color: #999;
    background-color: #292929;
    margin: 0;
    padding: 10px;
  }
}
@media screen and ( max-width: 768px){
  .container {
    padding-bottom: 81px;
  }
  .top{
    height: 620px;
    .top_con{
      width: 100%;
      .top_col{
        width: 100%;
      }
      .span3 dd{
        width: 100%;
      }
      .span4{
        width: 220px;
        height: 200px;
      }
    }
  }
}
</style>
